import { async } from "regenerator-runtime";
import { API_URL, RES_PER_PAGE, KEY } from "./config";
// import { getJSON, sendJSON } from "./helpers";
import { AJAX, isChrome, isSafari } from "./helpers";
import platform from "platform";

export const state = {
  recipe: {},
  search: {
    query: "",
    results: [],
    page: 1,
    resultsPerPage: RES_PER_PAGE,
  },
  bookmarks: [],
  recipes: [],
};

const createRecipeObject = function (data) {
  const { recipe } = data.data;
  return {
    id: recipe.id,
    title: recipe.title,
    publisher: recipe.publisher,
    sourceUrl: recipe.source_url,
    image: recipe.image_url,
    servings: recipe.servings,
    cookingTime: recipe.cooking_time,
    ingredients: recipe.ingredients,
    ...(recipe.key && { key: recipe.key }),
  };
};

export const createRecipeObjectLocal = function (recipe) {
  return {
    id: recipe.id || (Math.random() * 1000000).toString(),
    title: recipe.title,
    publisher: recipe.publisher,
    sourceUrl: recipe.sourceUrl,
    image: recipe.image,
    servings: recipe.servings,
    cookingTime: recipe.cookingTime,
    ingredients: recipe.ingredients,
    ...(recipe.key && { key: recipe.key }),
  };
};

const openRecipe = function (id) {
  return state.recipes.find(recipe => recipe.id === id);
};

export const loadRecipe = async function (id) {
  try {
    // const data = await AJAX(`${API_URL}/${id}?key=${KEY}`);
    const recipe = openRecipe(id)
    // state.recipe = createRecipeObject(data);
    state.recipe = createRecipeObjectLocal(recipe);

    if (state.bookmarks.some((bookmark) => bookmark.id === id))
      state.recipe.bookmarked = true;
    else state.recipe.bookmarked = false;

    // console.log(state.recipe);
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

const searchRecipe = function (query) {
  // const allRecipes = localStorage.getItem("recipes");
  // console.log(state.recipes)
  if (isSafari()) {
    const searchResult = state.recipes.filter(recipe => recipe.title.toUpperCase().includes(query.toUpperCase()));
    const searchResultWithoutDuplicates = searchResult.filter((recipe, index, array) =>
      array.findIndex(recipe2 => (recipe2.title === recipe.title)) === index);
    return searchResultWithoutDuplicates;
  }
  return state.recipes.filter(recipe => recipe.title.includes(query));
};

export const loadSearchResults = async function (query) {
  try {
    state.search.query = query;
    // const data = await AJAX(`${API_URL}?search=${query}&key=${KEY}`);
    const data = searchRecipe(query);
    // console.log(data);

    // state.search.results = data.data.recipes.map((rec) => {
    state.search.results = data.map((rec) => {
      return {
        id: rec.id,
        title: rec.title,
        publisher: rec.publisher,
        sourceUrl: rec.sourceUrl,
        image: rec.image,
        ...(rec.key && { key: rec.key }),
      };
    });
    //Reset the pagination history
    state.search.page = 1;
  } catch (err) {
    console.error(`${err} 💥💥💥`);
    throw err;
  }
};

export const getSearchResultsPage = function (page = state.search.page) {
  state.search.page = page;
  const start = (page - 1) * state.search.resultsPerPage;
  const end = page * state.search.resultsPerPage;

  return state.search.results.slice(start, end);
};

export const updateServings = function (newServings) {
  state.recipe.ingredients.forEach((ing) => {
    ing.quantity = (ing.quantity * newServings) / state.recipe.servings;
    //newQt = oldQT / oldSearvings// 2 * 8 / 4 = 4
  });

  state.recipe.servings = newServings;
};

const persistBookmarks = function () {
  localStorage.setItem("bookmarks", JSON.stringify(state.bookmarks));
};

export const addBookmark = function (recipe) {
  //Add bookmark
  state.bookmarks.push(recipe);

  //Mark current recipe as bookmark
  if (recipe.id === state.recipe.id) state.recipe.bookmarked = true;

  persistBookmarks();
};

export const deleteBookmark = function (id) {
  //Delete bookmark
  const index = state.bookmarks.findIndex((el) => el.id === id);
  state.bookmarks.splice(index, 1);

  //Mark current recipe as NOT bookmark
  if (id === state.recipe.id) state.recipe.bookmarked = false;

  persistBookmarks();
};

const init = function () {
  const bookmarks = localStorage.getItem("bookmarks");
  const recipes = localStorage.getItem("recipes");
  if (bookmarks) state.bookmarks = JSON.parse(bookmarks);
  if (recipes) state.recipes = JSON.parse(recipes);
};

init();

// WHILE TESTING AND DEVELOPING
const clearBookmarks = function () {
  localStorage.clear("bookmarks");
};
// clearBookmarks()


const addRecipeToLocalStorage = function (recipe) {
  state.recipes.push(recipe);
  localStorage.setItem("recipes", JSON.stringify(state.recipes));
};

export const uploadRecipe = async function (newRecipe) {
  try {
    const ingredients = Object.entries(newRecipe)
      .filter((entry) => entry[0].startsWith("ingredient") && entry[1] !== "")
      .map((ing) => {
        // const ingArr = ing[1].replaceAll(" ", "").split(",");
        const ingArr = ing[1].split(",").map((el) => el.trim());
        if (ingArr.length !== 3)
          throw new Error(
            "Wrong ingredient format! Please use the correct format :)"
          );

        const [quantity, unit, description] = ingArr;

        return { quantity: quantity ? +quantity : null, unit, description };
      });

    const recipe = {
      title: newRecipe.title,
      sourceUrl: newRecipe.sourceUrl,
      image: newRecipe.image,
      publisher: newRecipe.publisher,
      cookingTime: +newRecipe.cookingTime,
      servings: +newRecipe.servings,
      ingredients,
    };

    // state.recipe = createRecipeObject(recipe);
    state.recipe = createRecipeObjectLocal(recipe);
    // const data = await AJAX(`${API_URL}?key=${KEY}`, recipe);
    addRecipeToLocalStorage(state.recipe);

    if (isChrome()) {
      addBookmark(state.recipe);
    }
  } catch (err) {
    throw err;
  }
};
