import icons from "url:../../img/icons.svg";
import View from "./View.js";
import { isChrome, isSafari } from "../helpers.js";

class AddRecipeView extends View {
  _parentElement = document.querySelector(".upload");
  _message = "Recipe was successfully uploaded :)";

  _window = document.querySelector(".add-recipe-window");
  _overlay = document.querySelector(".overlay");
  _btnOpen = document.querySelector(".nav__btn--add-recipe");
  _btnClose = document.querySelector(".btn--close-modal");

  _servingsField = document.querySelector('[name="servings"]');
  _cookingTimeField = document.querySelector('[name="cookingTime"]');
  _cookingTimeLabel = document.querySelector('[name="cookingTimeLabel"]');

  _logoLabel = document.querySelector('[alt="Logo"]');

  constructor() {
    super();
    this._addHandlerShowWindow();
    this._addHandlerHideWindow();

    if (isSafari()) {
      this._servingsField.setAttribute('min', 1);
      this._servingsField.setAttribute('max', 100);

      this._cookingTimeField.setAttribute('min', 1);
      this._cookingTimeField.setAttribute('max', 525600);
      this._cookingTimeLabel.innerHTML = 'Preparation time';

      const logoLink = document.createElement('a');
      logoLink.appendChild(this._logoLabel.cloneNode(true));
      this._logoLabel.parentNode.replaceChild(logoLink, this._logoLabel);
      logoLink.setAttribute('href', '/');
    }

  }

  toggleWindow() {
    this._overlay.classList.toggle("hidden");
    this._window.classList.toggle("hidden");
  }

  _addHandlerShowWindow() {
    this._btnOpen.addEventListener("click", this.toggleWindow.bind(this));
  }

  _addHandlerHideWindow() {
    this._btnClose.addEventListener("click", this.toggleWindow.bind(this));
    this._overlay.addEventListener("click", this.toggleWindow.bind(this));
  }

  addHandlerUpload(handler) {
    this._parentElement.addEventListener("submit", function (e) {
      e.preventDefault();

      const dataArr = [...new FormData(this)];
      const data = Object.fromEntries(dataArr);
      handler(data);
    });
  }
}

export default new AddRecipeView();
