import { async } from "regenerator-runtime";
import { TIMEOUT_SEC } from "./config.js";
import platform from "platform";

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

export const AJAX = async function (url, uploadData = undefined) {
  try {
    const fetchPro = uploadData
      ? fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(uploadData),
      })
      : fetch(url);

    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    const data = await res.json();

    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err;
  }
};

const isBrowser = function (browserName) {
  return platform.name.includes(browserName);
}

export const isChrome = function () {
  return isBrowser('Chrome');
}
export const isSafari = function () {
  return isBrowser('Safari');
}